import React, { FC } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import EditPayRateFormikDatePicker from 'components/shared/forms/datePickers/EditPayRateFormikDatePicker';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import CustomFormikSelect from 'components/shared/forms/CustomFormikSelect';
import Button from 'components/shared/Button';
import { MaxLength8Symbols } from 'constants/formsConstants';
import { UserPayType } from 'features/user/userTypes';
import {
  UpdateRateHistoryEntryData,
  RateType,
} from 'features/memberInfo/memberInfoTypes';
import { setTimeInDate } from 'helpers/setTimeInDate';
import styles from '../EditPaymentsForm.module.scss';

export interface EditPayRateFormProps {
  memberId: number;
  payType: keyof typeof UserPayType;
  payAmount: RateType;
  memberTimeZone: string;
  currentRateChangingDate: string;
  onSubmit: (memberId: number, payload: UpdateRateHistoryEntryData) => void;
}

export interface EditPayRateFormValues {
  payType: { value: keyof typeof UserPayType; label: keyof typeof UserPayType };
  payRate: string;
  startDate: Date;
}

const EditPayRateForm: FC<EditPayRateFormProps> = ({
  memberId,
  payType,
  payAmount,
  memberTimeZone,
  currentRateChangingDate,
  onSubmit,
}) => {
  const InitialValues: EditPayRateFormValues = {
    payType: { value: payType || '', label: payType || '' },
    payRate: +payAmount.value > 0 ? payAmount.value : '',
    startDate: new Date(),
  };

  const validationSchema = Yup.object({
    payRate: Yup.number()
      .typeError('You must specify a number')
      .test(
        'len',
        'Enter a smaller number',
        (val = 0) => val.toString().length <= MaxLength8Symbols
      ),
  });
  const payTypeOptions = (
    Object.keys(UserPayType) as Array<keyof typeof UserPayType>
  ).map((key) => ({
    value: key,
    label: key,
  }));

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formattedStartDate = zonedTimeToUtc(
            setTimeInDate(values.startDate, 0, 0),
            memberTimeZone
          ).toISOString();
          onSubmit(memberId, {
            paymentRateType: "payRate",
            rate: values.payRate || '0',
            type: values.payType.value,
            startDate: formattedStartDate,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <CustomFormikSelect
              label={'Pay type'}
              name="payType"
              options={payTypeOptions}
            />

            <div className={styles.rateFlex}>
              {formik.values.payType.value === UserPayType.Hourly && (
                <ValidationTextInput
                  label="Pay rate"
                  name="payRate"
                  type="text"
                  placeholder="Add pay rate USD/hr"
                />
              )}

              {formik.values.payType.value === UserPayType.Fixed && (
                <ValidationTextInput
                  label="Fixed amount"
                  name="payRate"
                  type="text"
                  placeholder="USD"
                />
              )}

              <EditPayRateFormikDatePicker
                label="Effective date"
                name="startDate"
                maxDate={new Date()}
                currentRateDate={new Date(currentRateChangingDate)}
              />
            </div>

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPayRateForm;
