import { toast } from 'react-toastify';

export interface MyKnownError {
  message?: string;
  response: {
    data: { message: string };
    status: number;
  };
}

export const thunkErrorHandler = (error: MyKnownError, api: any) => {
  console.log('ERROR: ', error);
  if (error.response.status >= 500) {
    toast.error(error.message);
  } else {
    error.response.data.message
      ? toast.error(error.response.data.message)
      : toast.error(error.message);
  }
  // if (error.response.data.message) {
  //   return api(error.response.data.message);
  // } else {
  //   return api(error.message);
  // }
};

//classic asyncThunk catch

// catch (err) {
//   const error = err as MyKnownError;
//   console.log('ERROR: ', error);
//   if (error.response.status >= 500) {
//     toast.error(error.message);
//   } else {
//     error.response.data.message
//       ? toast.error(error.response.data.message)
//       : toast.error(error.message);
//   }
//   return thunkApi.rejectWithValue(error.response.data.message);
// }
