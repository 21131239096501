import axios from 'axios';
import { userManager } from '../features/user/userSlice';

const client = axios.create({
  headers: { 'Content-Type': 'application/json' },
  baseURL: process.env.REACT_APP_BACKEND_API,
});

client.interceptors.request.use(
  async (config) => {
    const user = await userManager.getUser();
    const token = user?.access_token;
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      await userManager.signinRedirect();
    }
    // if (error.response.status === 403) {
    //   window.location.href = '/403forbidden';
    // }
    return Promise.reject(error);
  }
);

export default client;
