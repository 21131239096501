import {
  IGetTimeAndActivityReportsParams,
  TimeAndActivityReportsParamsForDownloading,
} from 'features/timeAndActivityReports/timeAndActivityReportsTypes';
import client from './axiosInstance';
import { AxiosResponse } from 'axios';

export type CreateDownloadLinkFromPdfParams = {
  startDate: string;
  endDate: string;
  organizationName: string;
  reportType: 'timeAndActivity' | 'amountsOwed';
};

export const createDownloadLinkFromPdf = (
  params: CreateDownloadLinkFromPdfParams
) => {
  return (response: AxiosResponse<Blob>) => {
    const { startDate, endDate, organizationName, reportType } = params;
    const url = window.URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${organizationName}_${startDate}_${endDate}_${reportType}.pdf`;
    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);

    window.open(url, '_blank');
  };
};

export const fetchTimeAndActivityReports = async (
  params: IGetTimeAndActivityReportsParams
) => {
  const {
    isMyReports,
    startDate,
    endDate,
    columns,
    sumDateRanges,
    noTimeOff,
    clientsIds,
    projectsIds,
    membersIds,
  } = params;
  const startDateQuery = startDate ? `startDate=${startDate}&` : '';
  const endDateQuery = endDate ? `endDate=${endDate}&` : '';
  const columnsQuery =
    columns && columns.length > 0
      ? `columns=${columns.join('&columns=')}&`
      : '';
  const sumDateRangesQuery = sumDateRanges
    ? `sumDateRanges=${sumDateRanges}&`
    : '';
  const noTimeOffQuery = noTimeOff
    ? `noTimeOff=${noTimeOff}&`
    : '';
  const clientsQuery =
    clientsIds && clientsIds.length > 0
      ? `clientsIds=${clientsIds.join('&clientsIds=')}&`
      : '';
  const projectsQuery =
    projectsIds && projectsIds.length > 0
      ? `projectsIds=${projectsIds.join('&projectsIds=')}&`
      : '';
  const membersQuery =
    membersIds && membersIds.length > 0
      ? `membersIds=${membersIds.join('&membersIds=')}&`
      : '';

  const queryStr = `?${startDateQuery}${endDateQuery}${columnsQuery}${sumDateRangesQuery}${noTimeOffQuery}${clientsQuery}${projectsQuery}${membersQuery}`;

  if (isMyReports) {
    return await client.get(`/reports/time-and-activity/my${queryStr}`);
  } else {
    return await client.get(`/reports/time-and-activity${queryStr}`);
  }
};

export const downloadTimeAndActivityReports = async (
  params: TimeAndActivityReportsParamsForDownloading
) => {
  const {
    startDate,
    endDate,
    columns,
    sumDateRanges,
    clientsIds,
    projectsIds,
    membersIds,
    organizationName,
    reportType,
  } = params;
  const startDateQuery = startDate ? `startDate=${startDate}&` : '';
  const endDateQuery = endDate ? `endDate=${endDate}&` : '';
  const columnsQuery =
    columns && columns.length > 0
      ? `columns=${columns.join('&columns=')}&`
      : '';
  const sumDateRangesQuery = sumDateRanges
    ? `sumDateRanges=${sumDateRanges}&`
    : '';
  const clientsQuery =
    clientsIds && clientsIds.length > 0
      ? `clientsIds=${clientsIds.join('&clientsIds=')}&`
      : '';
  const projectsQuery =
    projectsIds && projectsIds.length > 0
      ? `projectsIds=${projectsIds.join('&projectsIds=')}&`
      : '';
  const membersQuery =
    membersIds && membersIds.length > 0
      ? `membersIds=${membersIds.join('&membersIds=')}&`
      : '';

  const queryStr = `?${startDateQuery}${endDateQuery}${columnsQuery}${sumDateRangesQuery}${clientsQuery}${projectsQuery}${membersQuery}`;

  await client
    .get(`/reports/time-and-activity/pdf${queryStr}`, {
      headers: { 'Content-Type': 'application/pdf' },
      responseType: 'blob',
    })
    .then(
      createDownloadLinkFromPdf({
        startDate,
        endDate,
        reportType,
        organizationName,
      })
    )
    .catch((error: unknown) => {
      console.error('Error downloading file:', error);
    });
};
