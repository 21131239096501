import React, { FC } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import EditPayRateFormikDatePicker from 'components/shared/forms/datePickers/EditPayRateFormikDatePicker';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import { MaxLength8Symbols } from 'constants/formsConstants';
import {
  UpdateRateHistoryEntryData,
  RateType,
} from 'features/memberInfo/memberInfoTypes';
import { setTimeInDate } from 'helpers/setTimeInDate';
import styles from '../EditPaymentsForm.module.scss';

export interface EditPaymentsFormProps {
  memberId: number;
  billRate: RateType;
  memberTimeZone: string;
  currentRateChangingDate: string;
  onSubmit: (memberId: number, values: UpdateRateHistoryEntryData) => void;
}

const EditBillRateForm: FC<EditPaymentsFormProps> = ({
  memberId,
  billRate,
  memberTimeZone,
  currentRateChangingDate,
  onSubmit,
}) => {
  const InitialValues = {
    billRate: +billRate.value > 0 ? billRate.value : '',
    startDate: new Date(),
  };

  const validationSchema = Yup.object({
    billRate: Yup.number()
      .typeError('You must specify a number')
      .test(
        'len',
        'Enter a smaller number',
        (val = 0) => val.toString().length <= MaxLength8Symbols
      ),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const formattedStartDate = zonedTimeToUtc(
            setTimeInDate(values.startDate, 0, 0),
            memberTimeZone
          ).toISOString();
          onSubmit(memberId, {
            paymentRateType: "billRate",
            rate: values.billRate || '0',
            startDate: formattedStartDate,
          });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <div className={styles.rateFlex}>
              <ValidationTextInput
                label="Bill rate"
                name="billRate"
                type="text"
                placeholder="Add bill rate USD/hr"
              />

              <EditPayRateFormikDatePicker
                label="Effective date"
                name="startDate"
                maxDate={new Date()}
                currentRateDate={new Date(currentRateChangingDate)}
              />
            </div>

            <div className={styles.submitWrapper}>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Save changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditBillRateForm;
